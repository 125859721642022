
export const environment = {
  production: false,
  apiUrl: 'https://api.dev.digitalreception.ch/v1',
  defaultAppLanguage: 'de',
  hasRemoteLogging: false,
  title: 'ZIP Stücki Park',
  instanceReception: {
    key: '',
    label: ''
  },
  instanceKey: 'zip',
  templateKey: '',
  cardDispenserExtensionId: '',
  logoUrl: '/assets/img/logo/zip-logo-dark.svg',
  carouselImages: [
    'assets/img/carousel/zip/slide1.jpg',
    'assets/img/carousel/zip/slide2.jpg',
    'assets/img/carousel/zip/slide3.jpg',
    'assets/img/carousel/zip/slide4.jpg',
  ]
};
